import Sprite from "app/components/sprite";
import {
  EmailOpenRateChart,
  MessagingOpenRateChart,
  ShoppersChatBeliefChart,
  ShoppersEngagementBeliefChart,
  ShoppersSocialChannelBeliefChart,
} from "app/components/static-charts/homepage/";
import { Trans } from "react-i18next";

const LargeChartTitle = ({ children }) => (
  <div className="flex flex-row justify-center">
    <span
      className="max-w-[6rem] pt-2 text-center font-bold leading-5 text-white 
      md:pt-4"
    >
      {children}
    </span>
  </div>
);

const LargeChartContainer = ({ children }) => (
  <div className="max-w-[120px] md:max-w-[164px]">{children}</div>
);

const SmallChartContainer = ({ children }) => (
  <div>
    <div className="max-w-[100px]">{children}</div>
  </div>
);

const SmallChartTitle = ({ children }) => (
  <div className="flex-1">
    <div className="flex h-full flex-col justify-center pl-4 rtl:pr-5 lg:pl-5">
      <span className="text-[14px] leading-5 text-white">{children}</span>
    </div>
  </div>
);

const RateGraphs = () => {
  return (
    <div className="w-full">
      <div className="flex w-full flex-row justify-center">
        <LargeChartContainer>
          <MessagingOpenRateChart />
          <LargeChartTitle>
            <Trans>Messaging Open Rate</Trans>
          </LargeChartTitle>
        </LargeChartContainer>
        <div
          className="flex flex-row items-center justify-center px-[23px] 
          lg:px-[25px]"
        >
          <span className="text-[26px] leading-[45px] text-white lg:pb-14">
            <Trans>VS</Trans>
          </span>
        </div>
        <LargeChartContainer>
          <EmailOpenRateChart />
          <LargeChartTitle>
            <Trans>Email Open Rate</Trans>
          </LargeChartTitle>
        </LargeChartContainer>
      </div>
      <div className="flex w-full flex-row justify-around">
        <div className="mt-[52px] lg:mt-[70px]">
          <div className="text-center">
            <h3
              className="text-[45px] font-extrabold leading-[45px]
                text-yellow-dark"
            >
              <Trans>4 hours</Trans>
            </h3>
            <p
              className="mt-8 max-w-[230px] text-sm font-normal
              leading-5 text-white lg:mt-[5px]"
            >
              <Trans>
                is the average daily time spent on social and messaging channels
                by shoppers.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ShopperGraphs = () => {
  return (
    <div className="flex w-full flex-row justify-center pt-10 lg:pt-0">
      <div className="max-w-[440px]">
        <div className="flex flex-row">
          <SmallChartContainer>
            <ShoppersSocialChannelBeliefChart />
          </SmallChartContainer>
          <SmallChartTitle>
            <Trans>
              of shoppers believe social channels help them make a shopping
              decision.
            </Trans>
          </SmallChartTitle>
        </div>
        <div className="mt-[50px] flex flex-row lg:mt-[54px]">
          <SmallChartContainer>
            <ShoppersEngagementBeliefChart />
          </SmallChartContainer>
          <SmallChartTitle>
            <Trans>
              of shoppers say they would only engage with personalized marketing
              messages.
            </Trans>
          </SmallChartTitle>
        </div>
        <div className="mt-[50px] flex flex-row lg:mt-[54px]">
          <SmallChartContainer>
            <ShoppersChatBeliefChart />
          </SmallChartContainer>
          <SmallChartTitle>
            <Trans>
              of shoppers say they would rather buy from companies they can
              reach by chat.
            </Trans>
          </SmallChartTitle>
        </div>
      </div>
    </div>
  );
};

export const WhyCCommerceSection = () => {
  return (
    <section
      className="relative w-full bg-primary font-montserrat
      text-primary lg:h-screen"
    >
      <div className="absolute z-[1] h-full w-full overflow-hidden">
        <div className="zbooni-layout relative">
          <Sprite
            name="zigzag-translucent"
            fill="#FFF"
            className="absolute left-0 top-0 h-[262px]
                w-[524px] translate-x-[-40%]
                translate-y-[84px] transform
                rtl:translate-x-[140%] md:h-[372px]
                md:w-[746px]
            "
          />
        </div>
      </div>
      <div className="zbooni-layout relative z-[2] h-full">
        <div className="flex h-full w-full items-center justify-center">
          <div
            className="mb-[100px] mt-[150px]
            pt-0 lg:mt-0
            lg:max-h-[700px] lg:pt-[150px]
            xl:max-h-[800px] xl:px-12
          "
          >
            <h2
              className="text-center text-[30px] font-extrabold
              leading-[44px] text-white lg:text-[33px]
            "
            >
              <Trans>Why cCommerce?</Trans>
            </h2>
            <div className="mt-20">
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-[170px]">
                <RateGraphs />
                <ShopperGraphs />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
