import CcommerceReport from "app/components/widgets/ccommerce-report";
import CShopMarket from "app/components/widgets/cshop-market";
import { getLangDir, isRtlLang } from "app/hooks/rtl-detect";
import { useI18nLanguage } from "app/hooks/i18n";
import { useEffect, useRef } from "react";

const widgets = [
  { id: "ccommerce-report-widget", Component: <CcommerceReport /> },
  { id: "cshop-market-widget", Component: <CShopMarket /> },
];

const WidgetBox = () => {
  const { locale: language } = useI18nLanguage();
  const swiperRef = useRef(null);
  const paginationRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      dir: getLangDir(language),
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: "auto",
          centeredSlides: true,
        },
        768: {
          slidesPerView: "1",
        },
      },
      pagination: {
        el: paginationRef.current,
        clickable: true,
        bulletClass: "custom-pagination-bullet",
        bulletActiveClass: "active-custom-pagination-bullet",
        renderBullet: (index, className) => {
          return `<span class="${className} bullet-${index}"></span>`;
        },
      },
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
    swiperContainer.swiper.rtl = isRtlLang(language);
    swiperContainer.swiper.rtlTranslate = isRtlLang(language);
  }, [language]);

  return (
    <div className="overflow-visible bg-secondary-lightest pb-5 px-5 md:overflow-hidden md:rounded-2xl md:bg-transparent md:pb-0 md:px-0">
      <div className="relative -top-10 md:top-0">
        <swiper-container
          ref={swiperRef}
          init="false"
          key={`widget-box-${language}-swiper`}
        >
          {widgets.map((widget) => (
            <swiper-slide key={widget.id} className="px-5 xs:px-6 md:px-0">
              {widget.Component}
            </swiper-slide>
          ))}
        </swiper-container>
        <div
          ref={paginationRef}
          className="flex justify-center gap-2.5 bg-secondary-lightest mt-3 md:bg-transparent"
        />
      </div>
    </div>
  );
};

export default WidgetBox;
