import { createCanvasLinearGradient } from "app/utils/canvas-utils";

export const gradientConfigs = {
  borderColor: "#00000000",
  backgroundColor: (context) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    if (!chartArea) {
      return;
    }
    return [
      createCanvasLinearGradient({
        context: ctx,
        chartArea,
        colors: [
          { stop: 0.22, color: "#E5FF00" },
          { stop: 0.6, color: "#70FF64" },
          { stop: 0.78, color: "#1EFFFF" },
        ],
        position: (area) => [30, area.top, area.right - 30, area.bottom],
      }),
      "#4E515B",
    ];
  },
};
