import ProgressBar from "@ramonak/react-progress-bar/dist/index";
import Sprite from "app/components/sprite";
import { useAnimation } from "app/hooks/useAnimation";
import { useI18nLanguage } from "app/hooks/i18n";
import mainImage from "app/images/homepage-images/capture-orders/capture-orders-main.png";
import arabicCart from "app/images/homepage-images/capture-orders/cart-arabic.png";
import arabicCartItem from "app/images/homepage-images/capture-orders/cart-item-arabic.png";
import cartItem from "app/images/homepage-images/capture-orders/cart-item.png";
import cart from "app/images/homepage-images/capture-orders/cart.png";
import ClientOnly from "app/components/ClientOnly";
import Link from "app/components/i18n/Link";
import { useRef } from "react";
import CountUp from "react-countup";
import { Trans } from "react-i18next";
import { InView } from "react-intersection-observer";

export const CaptureOrders = () => {
  const mainSection = useRef(null);
  const mainImg = useRef(null);
  const mobileImg = useRef(null);
  const shoppingCard = useRef(null);
  const mainTopic = useRef(null);
  const mainDescription = useRef(null);
  const mainButton = useRef(null);
  const mainLogo = useRef(null);

  useAnimation([
    mainSection,
    mainImg,
    mainDescription,
    mobileImg,
    shoppingCard,
    mainTopic,
    mainButton,
    mainLogo,
  ]);

  const { locale: language } = useI18nLanguage();
  return (
    <section className="relative z-[1] overflow-x-hidden bg-primary font-montserrat text-white lg:h-screen">
      <div
        ref={mainSection}
        className="zbooni-layout flex h-full w-full items-center opacity-0 md:h-5/6 xl:h-4/5"
        data-animation="animate__fadeIn"
      >
        <Sprite
          name="diamond"
          className="absolute z-[-1] h-[400px] w-[420px] translate-y-[20%] transform ltr:translate-x-[-28%] rtl:translate-x-[-5%] md:h-[600px] md:w-[620px] md:translate-x-[-20%] lg:translate-y-32 ltr:lg:translate-x-32 rtl:lg:-translate-x-32 2xl:h-[800px] 2xl:w-[820px]"
        />
        <div className="z-[2] grid h-full max-h-[700px] flex-1 grid-cols-6 pt-[84px]">
          <div className="col-start-1 col-end-7 flex flex-col lg:flex-row">
            <div className="hidden flex-1 lg:block"></div>
            <div
              ref={mainLogo}
              data-animation="animate__fadeIn animate__animated"
              className="flex flex-1 items-center"
            >
              <Sprite
                name="zbooni-cool"
                className="inline h-[61px] w-[76px] ltr:mr-5 tl:ml-5 lg:mx-5 lg:h-[61px] lg:w-[82px]"
              />
              <p className="inline-block text-3xl font-black leading-tight ltr:w-1/5 rtl:w-1/2 lg:text-4xl rtl:xl:w-1/3">
                <Trans>Capture Orders.</Trans>
              </p>
            </div>
          </div>

          <div className="col-start-1 col-end-7 row-span-3 flex flex-col justify-center lg:col-end-4">
            <div>
              <p
                ref={mainTopic}
                data-animation="animate__fadeInDown"
                className="animate__animated my-4 w-full text-lg font-extrabold lg:w-8/12 lg:text-2xl"
              >
                <Trans>
                  Turn one to one customer interactions into transactions.
                </Trans>
              </p>
              <p
                ref={mainDescription}
                data-animation="animate__fadeInDown animate__delay-1s"
                className="animate__animated mb-6 max-w-[356px] text-sm font-normal md:mb-8 lg:mb-14 lg:max-w-[300px] lg:text-base lg:font-medium xl:max-w-[356px]"
              >
                <Trans>
                  Easily create invoices and orders to close sales on messaging
                  apps you already use.
                </Trans>
              </p>
              <Link
                to="/features/capture-orders/#details"
                id="cta_homepage_captureOrders_viewFeatures_in"
              >
                <button
                  ref={mainButton}
                  data-animation="animate__fadeInDown animate__delay-2s"
                  className="yellow-green-cyan-btn animate__animated rounded-full px-7 py-[15px]"
                >
                  <p className="l g:text-base text-sm font-extrabold text-primary">
                    <Trans>View features</Trans>
                  </p>
                </button>
              </Link>
            </div>
          </div>

          <div className="relative col-span-3 col-start-1 col-end-7 row-span-5 my-5 -ml-7 flex min-h-[250px] w-screen items-center md:-ml-16 lg:col-start-4 lg:col-end-7 lg:ml-0 lg:w-auto">
            <div className="absolute top-32 z-20 h-full w-full scale-75 ltr:-left-4 rtl:-right-14 md:top-20 md:scale-90 ltr:md:left-14 rtl:md:-right-4 ltr:lg:-left-36 rtl:lg:-right-32 xl:top-36 xl:scale-100 rtl:xl:-right-36">
              <div
                ref={mobileImg}
                className="absolute h-[430px] w-[224px] ltr:left-0 rtl:right-0"
                data-animation="animate__animated animate__slideInUp"
              >
                <img
                  className="rounded-3xl drop-shadow-icon filter"
                  src={language == "ar" ? arabicCart : cart}
                  alt="card-long"
                />
              </div>
              <div
                ref={shoppingCard}
                data-animation="zoom-in-fade-in animate__delay-2s"
                className="animate__animated absolute top-36 h-[203px] w-[183px] transform ltr:left-[216px] rtl:right-[216px]"
              >
                <img
                  className="rounded-2xl drop-shadow-icon filter"
                  src={language == "ar" ? arabicCartItem : cartItem}
                  alt="card-short"
                />
              </div>
            </div>
            <img
              ref={mainImg}
              className="animate__animated z-10 mt-4 h-full max-h-[240px] w-auto drop-shadow-icon filter ltr:ml-10 rtl:ml-16 md:max-h-[393px] ltr:md:ml-auto rtl:md:mr-auto ltr:lg:ml-0 rtl:lg:mx-0"
              src={mainImage}
              alt="capture-orders-main"
              data-animation="animate__slideInDown"
            />
          </div>
          <div className="z-2 col-start-1 col-end-4 row-span-2 hidden h-28 py-3 lg:block lg:h-auto">
            <div className="hidden flex-col items-start justify-center lg:flex">
              <ClientOnly>
                <InView as="div" rootMargin="50px">
                  {({ inView, ref }) => (
                    <div ref={ref} style={{ height: 36 }}>
                      {inView ? (
                        <CountUp
                          end={81}
                          suffix="%"
                          duration={1}
                          className="inline text-3xl font-extrabold"
                        />
                      ) : (
                        <p className="text-3xl font-extrabold">81%</p>
                      )}
                    </div>
                  )}
                </InView>
              </ClientOnly>
              <div className="relative mb-4 mt-2 h-1 w-5/12">
                <ClientOnly>
                  <InView as="div" rootMargin="50px">
                    {({ inView, ref }) => (
                      <div ref={ref} style={{ height: 20 }}>
                        {inView ? (
                          <ProgressBar
                            animateOnRender
                            borderRadius="10"
                            className="progress-wrapper"
                            barContainerClassName="bg-secondary-darkest"
                            completedClassName="bg-completed"
                            labelClassName="invisible"
                            completed={80}
                          />
                        ) : (
                          <div className="h-[4px] w-full bg-secondary-darkest"></div>
                        )}
                      </div>
                    )}
                  </InView>
                </ClientOnly>
              </div>
              <p className="block text-xs">
                <Trans>Conversion rate on Zbooni</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-primary pb-12 pt-24 text-white md:h-1/6 md:pt-0 lg:bg-secondary-lightest lg:text-primary xl:h-1/5">
        <div className="hidden flex-1 md:block"></div>
        <div className="flex flex-1 flex-col justify-center px-7 pt-[150px] md:pt-60 lg:hidden lg:pt-0">
          <ClientOnly>
            <InView as="div" rootMargin="50px">
              {({ inView, ref }) => (
                <div ref={ref} style={{ height: 36 }}>
                  {inView ? (
                    <CountUp
                      end={81}
                      suffix="%"
                      duration={1}
                      className="text-3xl font-extrabold"
                    />
                  ) : (
                    <p className="text-3xl font-extrabold">81%</p>
                  )}
                </div>
              )}
            </InView>
          </ClientOnly>
          <div className="z-1 relative mb-4 mt-2 h-1 w-8/12 bg-secondary-darkest md:w-6/12">
            <ClientOnly>
              <InView as="div" rootMargin="50px">
                {({ inView, ref }) => (
                  <div ref={ref} style={{ height: 20 }}>
                    {inView ? (
                      <ClientOnly>
                        <ProgressBar
                          animateOnRender
                          borderRadius="10"
                          className="progress-wrapper"
                          barContainerClassName="bg-secondary-darkest"
                          completedClassName="bg-completed"
                          labelClassName="invisible"
                          completed={80}
                        />
                      </ClientOnly>
                    ) : (
                      <div className="h-[4px] w-full bg-secondary-darkest"></div>
                    )}
                  </div>
                )}
              </InView>
            </ClientOnly>
          </div>
          <p className="block text-xs">
            <Trans>Conversion rate on Zbooni</Trans>
          </p>
        </div>
      </div>
    </section>
  );
};
