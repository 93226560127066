import { json } from "@remix-run/react";
import { getMeta } from "app/utils/meta";
import { getLoaderTrans } from "app/.server/utils";
import Layout from "app/components/layout";
import WidgetBox from "app/components/widgets/widget-box";
import {
  AcceptPayments,
  BusinessJustGotPersonalSection,
  CaptureOrders,
  HeroSection,
  SellMore,
  TrackEverything,
  WhoWeServeSection,
  WhyCCommerceSection,
} from "app/sections/homepage/index";
import { useEffect, useState } from "react";
import config from "app/config/i18n";

/**
 * @type {import ('@remix-run/react').MetaFunction}
 */
export const meta = ({ data }) =>
  getMeta({
    title: data.meta.title,
    description: data.meta.description,
  });

export const loader = async ({ request, params }) => {
  const trans = await getLoaderTrans({ request, params });

  const page = trans("Home");
  const title = `${page} | ${trans("The ultimate social commerce platform")}`;
  const description = trans(
    "Customers are most receptive on social and messaging apps today. Bring the checkout experience to them, in real-time."
  );

  // this is done because entering a non-existent page is considered as a replacement for ($lang)
  const lang = params?.lang;
  if (lang && !config.supportedLngs.includes(lang)) {
    throw json({}, { status: 404 });
  }

  return json({
    meta: {
      title,
      description,
    },
  });
};

export const handle = {
  i18n: "translation",
};

export default function Index() {
  const [isOnTop, setIsOnTop] = useState(true);

  useEffect(() => {
    setIsOnTop(() => {
      if (window.scrollY == 0) return true;

      return false;
    });

    window.addEventListener("scroll", () => {
      setIsOnTop(window.scrollY < 200);
    });
  }, [isOnTop]);

  return (
    <Layout headerIsOnTop={isOnTop} enableHeaderShrinkingOnScroll={true}>
      <div className="overflow-x-hidden content-end">
        <HeroSection />
        <div className="md:hidden">
          <WidgetBox />
        </div>
        <BusinessJustGotPersonalSection />
        <CaptureOrders />
        <AcceptPayments />
        <SellMore />
        <TrackEverything />
        <WhyCCommerceSection />
        <WhoWeServeSection />
      </div>
    </Layout>
  );
}
