import Link from "app/components/i18n/Link";
import { useRef } from "react";

import Sprite from "app/components/sprite";

import { useAnimation } from "app/hooks/useAnimation";
import { useI18nLanguage } from "app/hooks/i18n";
import coffee from "app/images/homepage-images/sell-more/coffee.png";
import collectionsAr from "app/images/homepage-images/sell-more/collections-ar.png";
import collectionsEn from "app/images/homepage-images/sell-more/collections-en.png";
import cshopFeaturedAr from "app/images/homepage-images/sell-more/cshop-featured-ar.png";
import cshopFeaturedEn from "app/images/homepage-images/sell-more/cshop-featured-en.png";
import fashionEssentialsAr from "app/images/homepage-images/sell-more/fashion-essentials-ar.png";
import fashionEssentialsEn from "app/images/homepage-images/sell-more/fashion-essentials-en.png";
import mustHavesAr from "app/images/homepage-images/sell-more/must-haves-ar.png";
import mustHavesEn from "app/images/homepage-images/sell-more/must-haves-en.png";
import { Trans } from "react-i18next";

export const SellMore = () => {
  const mainSection = useRef(null);
  const coffeeImg = useRef(null);
  const mainTopic = useRef(null);
  const mainBody = useRef(null);
  const mainButton = useRef(null);

  const collectionsImg = useRef(null);
  const cshopFeaturedImg = useRef(null);
  const fashionEssentialsImg = useRef(null);
  const mustHavesImg = useRef(null);

  useAnimation([
    mainSection,
    coffeeImg,
    mainTopic,
    mainBody,
    mainButton,
    collectionsImg,
    cshopFeaturedImg,
    fashionEssentialsImg,
    mustHavesImg,
  ]);

  const { locale: language } = useI18nLanguage();

  return (
    <section className="relative h-auto overflow-hidden font-montserrat xs:bg-primary-lightest lg:h-screen">
      <Sprite
        className="absolute left-0 top-[690px] z-0 block h-[224px] w-[331px] lg:hidden"
        name="zigzag-mobile"
      />
      <div className="flex h-full flex-col justify-between md:pt-8">
        <div
          ref={mainSection}
          data-animation="animate__fadeIn animate__animated"
          className="flex h-full w-full items-center opacity-0 md:h-5/6 2xl:h-4/5"
        >
          <div className="w-full">
            <div className="zbooni-layout relative z-[3]">
              <Sprite
                className="absolute right-60 top-[700px] hidden h-[372px] w-[746px] lg:block xl:right-36 xl:top-28 rtl:xl:right-[36rem]"
                name="zigzag-sellmore"
              />
            </div>
            <div className="zbooni-layout relative z-[2] flex items-center pt-24">
              <Sprite
                className="h-[70px] w-20 fill-primary ltr:mr-6 rtl:ml-6 ltr:md:ml-[3.75rem] rtl:md:mr-[3.75rem] lg:fill-white ltr:lg:ml-0 rtl:lg:mr-0"
                name="sell-more-smiley"
              />
              <p className="max-w-[100px] text-3xl font-black leading-10 text-primary lg:text-[33px] lg:text-white">
                <Trans>Sell More.</Trans>
              </p>
            </div>
            <div className="relative z-[1]">
              <div className="absolute bottom-8 w-screen bg-transparent md:bg-primary lg:h-screen" />
              <div className="zbooni-layout flex flex-col sm:items-center lg:flex-row">
                <div className="relative flex-1">
                  <div
                    ref={coffeeImg}
                    data-animation="animate__animated animate__slideInDown"
                    className="relative top-12 ltr:right-12 rtl:left-12 ltr:sm:right-10 rtl:sm:left-10 ltr:md:right-24 rtl:md:left-24 ltr:lg:right-32 rtl:lg:left-32 ltr:xl:right-28 rtl:xl:left-28 ltr:md:-mr-16 rtl:md:-ml-16 w-[500px] xs:w-[420px] sm:w-[560px] xl:w-[680px] lg:h-[460px] overflow-hidden rounded-2xl"
                  >
                    <img alt="" src={coffee} className="rtl:scale-x-[-1]" />
                  </div>
                  <div className="absolute -top-2 ltr:-right-9 rtl:-left-9 scale-75 sm:top-12 ltr:sm:-right-4 ltr:md:-right-24 rtl:sm:-left-4 rtl:md:-left-24 lg:top-28 ltr:lg:right-4 ltr:xl:right-0 rtl:lg:left-4 rtl:xl:left-0 sm:scale-90 lg:scale-100">
                    <img
                      alt=""
                      ref={collectionsImg}
                      data-animation="animate__animated animate__fadeInDown animate__delay-1s"
                      className="max-w-[285px]"
                      src={language == "ar" ? collectionsAr : collectionsEn}
                    />
                    <img
                      alt=""
                      ref={cshopFeaturedImg}
                      data-animation="animate__animated animate__fadeInUp animate__delay-2s"
                      className="absolute top-[85px] ltr:-left-20 rtl:-right-20 max-w-[317px]"
                      src={language == "ar" ? cshopFeaturedAr : cshopFeaturedEn}
                    />
                    <img
                      alt=""
                      ref={fashionEssentialsImg}
                      data-animation="animate__animated animate__fadeInUp animate__delay-3s"
                      className="absolute top-[200px] ltr:left-12 rtl:right-12 max-w-[283px]"
                      src={
                        language == "ar"
                          ? fashionEssentialsAr
                          : fashionEssentialsEn
                      }
                    />
                    <img
                      alt=""
                      ref={mustHavesImg}
                      data-animation="animate__animated animate__fadeInUp animate__delay-4s"
                      className="absolute top-[304px] ltr:-left-9 rtl:-right-9 max-w-[272px]"
                      src={language == "ar" ? mustHavesAr : mustHavesEn}
                    />
                  </div>
                </div>
                <div className="flex-1 w-full top-[350px] z-10 text-black rtl:left-[280px] pt-20 pb-28 lg:pt-12 lg:text-white ltr:sm:pl-8 rtl:sm:pr-12 ltr:xl:pl-20 rtl:xl:pr-24">
                  <p
                    ref={mainTopic}
                    data-animation="animate__animated animate__fadeInDown"
                    className="max-w-lg font-bold leading-normal text-lg lg:text-2xl"
                  >
                    <Trans>
                      Attract new customers and increase repeat purchases.
                    </Trans>
                  </p>
                  <br />
                  <ul
                    ref={mainBody}
                    data-animation="animate__animated animate__fadeInDown animate__delay-1s"
                    className="list-disc xxs:mb-8 xxs:text-sm xs:mb-10 xs:text-sm lg:mb-12 lg:text-base"
                  >
                    <li className="ltr:ml-5 rtl:mr-5 lg:max-w-[420px]">
                      <Trans>
                        Gain access to a wide range of re-marketing tools,
                        including the ability to create and showcase your own
                        personalized collections.
                      </Trans>
                    </li>
                    <br />
                    <li className="ltr:ml-5 rtl:mr-5 lg:max-w-[420px]">
                      <Trans>
                        Get discovered on cShop Market, the first chat-to-shop
                        marketplace, and connect with an audience of hundreds of
                        thousands of customers eager to shop.
                      </Trans>
                    </li>
                  </ul>
                  <Link
                    to="/features/sell-more/#details"
                    id="cta_homepage_sellMore_discoverMore_in"
                  >
                    <button
                      ref={mainButton}
                      data-animation="animate__animated animate__fadeInDown animate__delay-2s"
                      className="dark-green-gradient-btn lg:green-gradient-btn"
                    >
                      <p>
                        <Trans>Discover more</Trans>
                      </p>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
