import DownloadAppButton from "app/components/buttons/download-app";
import RequestDemoButton from "app/components/buttons/request-demo";
import GetZbooniPopover from "app/components/get-zbooni-popover";
import Sprite from "app/components/sprite";
import WidgetBox from "app/components/widgets/widget-box";
import { useAnimation } from "app/hooks/useAnimation";
import arabianBusiness from "app/images/homepage-images/hero/arabian-business.png";
import heroBackground from "app/images/homepage-images/hero/background.png";
import { useRef, useState } from "react";
import { Trans } from "react-i18next";

// Just a utility object to keep animation configs DRY within this component.
const animationCss = {
  fadeInDown: {
    batch1: "animate__animated animate__fadeInDown",
    batch2: "animate__animated animate__fadeInDown animate__delay-1s",
    batch3: "animate__animated animate__fadeInDown animate__delay-2s",
  },
};

export const HeroSection = () => {
  const [popover, setPopover] = useState(false);
  const title = useRef(null);
  const description = useRef(null);
  const callToActions = useRef(null);

  const zbooniLink =
    "https://support.zbooni.com/?text=Hello%21%20I%20am%20interested%20in%20learning%20more%20about%20Zbooni.";

  useAnimation([title, description, callToActions]);
  return (
    <section
      className="relative bg-cover bg-bottom font-montserrat text-base text-white"
      style={{ backgroundImage: `url(${heroBackground})` }}
    >
      <GetZbooniPopover
        popover={popover}
        setPopover={setPopover}
        zbooniLink={zbooniLink}
      />
      <div className="zbooni-layout relative flex h-full flex-col items-center lg:flex-row">
        <div className="relative flex h-full min-h-[800px] w-full flex-col content-evenly md:h-screen md:max-h-[1080px] md:min-h-[900px]">
          <div className="flex flex-1 flex-col justify-center">
            <div
              ref={title}
              className="text-3xl font-black leading-snug opacity-0 xs:text-[34px] sm:text-[46px] lg:text-[74px] lg:leading-tight"
              data-animation={animationCss.fadeInDown.batch1}
            >
              <p>
                <Trans>Say hello</Trans>
              </p>
              <p>
                <Trans>to cCommerce.</Trans>
              </p>
            </div>
            <div
              ref={description}
              className="mt-9 w-full max-w-[500px] font-bold opacity-0 md:mt-4 lg:w-1/2 lg:max-w-[590px] lg:text-lg"
              data-animation={animationCss.fadeInDown.batch2}
            >
              <p>
                <Trans>
                  The next generation platform for personalized commerce. Drive
                  more sales with a human connection.
                </Trans>
              </p>
            </div>
            <div
              ref={callToActions}
              className="mt-10 flex flex-wrap gap-2 text-xs text-primary opacity-0 md:text-base lg:text-base"
              data-animation={animationCss.fadeInDown.batch3}
            >
              <DownloadAppButton
                id="cta_homepage_hero_downloadApp"
                additionalClassNames="w-[130px] py-3 font-extrabold md:w-[169px]"
                onClick={() => setPopover(true)}
              />
              <RequestDemoButton
                id="cta_homepage_hero_requestDemo"
                additionalClassNames="w-[130px] bg-white py-3 font-extrabold md:w-[169px]"
              />
            </div>
          </div>
          <div className="mb-6 flex flex-wrap content-end">
            <div className="grid w-full max-w-sm grid-cols-6 space-y-5 pb-12 sm:max-w-none md:grid-cols-12 md:space-y-0 md:pb-0">
              <div className="order-0 col-span-2 flex items-end lg:items-center xl:col-span-1">
                <p className="text-xs font-bold text-secondary lg:text-sm">
                  <Trans>Featured in</Trans>
                </p>
              </div>
              <div className="order-1 col-span-2 flex items-center">
                <Sprite
                  name="the-national"
                  className="h-[18px] w-[79px] lg:h-[30px] lg:w-[132px]"
                />
              </div>
              <div className="order-2 col-span-2 flex items-center">
                <Sprite
                  name="forbes"
                  className="h-[18px] w-[73px] lg:h-[25px] lg:w-[114px]"
                />
              </div>
              <div className="order-5 col-span-2 flex items-center md:order-3">
                <Sprite
                  name="entrepreneur"
                  className="h-[18px] w-[100px] lg:h-[24px] lg:w-[140px] xl:h-[29px] xl:w-[161px]"
                />
              </div>
              <div className="order-3 col-span-2 flex items-center md:order-4">
                <Sprite
                  name="dubai-eye"
                  className="h-[24px] w-[72px] md:ml-3 lg:h-[38px] lg:w-[112px] xl:ml-5"
                />
              </div>
              <div className="order-4 col-span-2 flex items-center md:order-5">
                <img
                  alt=""
                  className="h-[24px] w-[72px] lg:h-[31px] lg:w-[92px]"
                  src={arabianBusiness}
                />
              </div>
            </div>
          </div>
          <div className="bottom-28 hidden h-[200px] w-[490px] ltr:right-0 rtl:left-0 md:absolute md:block lg:bottom-28">
            <WidgetBox />
          </div>
        </div>
      </div>
    </section>
  );
};
