import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import { gradientConfigs } from "app/components/static-charts/homepage/configs";

export const EmailOpenRateChart = ({ innerText = "10" }) => {
  const data = {
    datasets: [
      {
        data: [10, 90],
        ...gradientConfigs,
      },
    ],
  };
  const options = {
    animation: {
      duration: 3000, // 3 seconds
    },
    cutout: "90%",
    responsive: true,
    tooltips: {
      enabled: false,
    },
    hover: {
      mode: null,
    },
    elements: {},
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="relative">
      <div
        className="z-2 absolute flex h-full w-full flex-row items-center 
        justify-center"
      >
        <label
          className="text-[36px] font-extrabold leading-[45px] text-white 
          md:text-[45px]"
        >
          {innerText}
          <span className="font-normal">%</span>
        </label>
      </div>
      <div className="z-1 relative">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

EmailOpenRateChart.propTypes = {
  innerText: PropTypes.string,
};
