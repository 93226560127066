import { ZBNI_CSHOP_MARKET_URL } from "app/config/constants";
import cshopMarketPhone from "app/images/homepage-images/hero/cshop-market-phone.png";
import cshopMarketVectorsSm from "app/images/homepage-images/hero/cshop-market-vectors-mobile.png";
import cshopMarketVectors from "app/images/homepage-images/hero/cshop-market-vectors.png";
import cshopMarketLogo from "app/images/logos/cshop-market.png";
import { Trans } from "react-i18next";

const CShopMarket = () => {
  return (
    <div className="z-10 md:w-[490px] md:rounded-2xl">
      <div className="cshop-market-widget relative mx-auto flex h-[420px] flex-col rounded-2xl sm:h-[200px] sm:max-w-[490px] sm:flex-row sm:gap-5 md:max-w-none lg:border-none">
        <div className="flex flex-1 flex-col justify-between p-5 text-primary sm:gap-y-3 md:gap-y-0">
          <div className="flex flex-col gap-y-4">
            <img alt="" src={cshopMarketLogo} className="w-24" />
            <p className="text-xl font-medium text-white sm:max-w-[180px] sm:text-2xl">
              <Trans>Discover, chat, and shop.</Trans>
            </p>
          </div>
          <div className="relative flex justify-center sm:hidden">
            <div className="w-56 border-b border-white">
              <img
                alt=""
                src={cshopMarketVectorsSm}
                className="absolute left-1/2 -translate-x-1/2"
              />
              <img
                alt=""
                src={cshopMarketPhone}
                className="relative z-20 mx-auto w-48"
              />
            </div>
          </div>
          <a
            id="cta-view-cshop-market"
            href={`${ZBNI_CSHOP_MARKET_URL}?utm_source=website&utm_medium=zbooni_web_home_carousel&utm_campaign=zbooni_cshop&utm_content=nav_zbooni_website`}
            rel="noreferrer"
            target="_blank"
            className="flex h-10 w-full items-center justify-center rounded-full bg-primary text-xs font-bold text-white md:max-w-[200px]"
          >
            <Trans>Explore cShop Market</Trans>
          </a>
        </div>
        <div className="relative hidden h-auto flex-1 items-center overflow-hidden px-5 ltr:pr-5 rtl:pl-5 sm:flex">
          <img
            alt=""
            src={cshopMarketVectors}
            className="absolute top-1 ltr:right-4 rtl:left-4 rtl:scale-x-[-1]"
          />
          <img
            alt=""
            src={cshopMarketPhone}
            className="relative top-3.5 w-40"
          />
        </div>
      </div>
    </div>
  );
};

export default CShopMarket;
