/* eslint-disable jsx-a11y/img-redundant-alt */
import Link from "app/components/i18n/Link";
import { useRef } from "react";

import Sprite from "app/components/sprite";
import { useAnimation } from "app/hooks/useAnimation";
import botChatbox from "app/images/homepage-images/business-just-got-personal/bot-chatbox.png";
import botChathead from "app/images/homepage-images/business-just-got-personal/bot-chathead.png";
import instagramHead from "app/images/homepage-images/business-just-got-personal/instagram.png";
import itemMessage from "app/images/homepage-images/business-just-got-personal/item-message.png";
import largeChatbox from "app/images/homepage-images/business-just-got-personal/large-chatbox.png";
import messengerHead from "app/images/homepage-images/business-just-got-personal/messenger.png";
import snapchatHead from "app/images/homepage-images/business-just-got-personal/snapchat.png";
import telegramHead from "app/images/homepage-images/business-just-got-personal/telegram.png";
import topChatbox from "app/images/homepage-images/business-just-got-personal/top-chatbox.png";
import topChathead from "app/images/homepage-images/business-just-got-personal/top-chathead.png";
import whatsappHead from "app/images/homepage-images/business-just-got-personal/whatsapp.png";
import { Trans } from "react-i18next";

export const BusinessJustGotPersonalSection = () => {
  // left side
  const mainSection = useRef(null);
  const mainHeader = useRef(null);
  const mainDescription = useRef(null);
  const callToAction = useRef(null);
  // right side
  const topChatMessage = useRef(null);
  const bottomChatMessage = useRef(null);
  const cartCheckoutBox = useRef(null);
  const snapchatIcon = useRef(null);
  const telegramIcon = useRef(null);
  const messengerIcon = useRef(null);
  const whatsappIcon = useRef(null);
  const instagramIcon = useRef(null);

  useAnimation([
    mainSection,
    mainHeader,
    mainDescription,
    callToAction,
    topChatMessage,
    bottomChatMessage,
    cartCheckoutBox,
    snapchatIcon,
    telegramIcon,
    messengerIcon,
    whatsappIcon,
    instagramIcon,
  ]);

  return (
    <section
      ref={mainSection}
      className="relative min-h-[700px] w-full
      overflow-hidden bg-secondary-lightest font-montserrat
      text-primary opacity-0 md:h-screen"
      data-animation="animate__fadeIn"
    >
      <div
        className="zbooni-layout flex h-full flex-col
        pb-20 md:flex-row md:pb-0"
      >
        <div
          className="z-10 flex flex-grow flex-col
          justify-center rtl:ml-8 md:flex-1"
        >
          <div
            ref={mainHeader}
            className="animate__animated max-w-xs text-4xl rtl:text-3xl 
            md:max-w-sm md:text-5xl rtl:md:text-5xl lg:max-w-xs lg:text-[66px] 
            rtl:lg:max-w-sm rtl:lg:text-[66px]"
            data-animation="animate__fadeInDown"
          >
            <p
              className="font-black leading-10
              md:leading-[55px] lg:leading-[75px]"
            >
              <Trans>Business just got personal.</Trans>
            </p>
          </div>
          <p
            ref={mainDescription}
            className="animate__animated mb-5 mt-4 max-w-[495px]
            text-base font-extrabold rtl:max-w-[270px] md:mb-12
            md:mt-7 md:text-lg lg:text-[22px] rtl:lg:max-w-[370px]"
            data-animation="animate__fadeInDown animate__delay-1s"
          >
            <Trans>
              Sell like never before. Introduce checkout to the channels that
              matter the most.
            </Trans>
          </p>
          <Link
            to="/pricing/all-in-one/"
            id="cta_homepage_businessJustGotPersonal_getStarted_in"
          >
            <button
              ref={callToAction}
              className="animate__animated dark-green-gradient-btn
              rounded-full py-2 md:py-4"
              data-animation="animate__fadeInDown animate__delay-2s"
            >
              <p className="text-sm md:text-base">
                <Trans>Get started</Trans>
              </p>
            </button>
          </Link>
        </div>
        <div className="mt-8 flex-grow md:mt-0 md:flex-1">
          <div
            className="relative flex h-[335px] items-center
            justify-center md:h-full"
          >
            {/* the approx w/h floating chatbox */}
            <div
              className="relative h-[335px] w-[305px]
              md:h-[467px] md:w-[431px] rtl:md:w-[420px]"
            >
              <Sprite
                name="less-than"
                fill="#FFF"
                className="absolute -bottom-4 -right-20 z-[1] h-[213px] 
                w-[284px] md:-right-40 rtl:md:-right-64 lg:-right-32 "
              />
              <Sprite
                name="greater-than"
                fill="#FFF"
                className="absolute -left-28 top-4 z-[1] h-[213px] w-[284px] 
                rtl:-left-36 sm:-left-36 md:-left-48 md:h-[330px] md:w-[388px]
                lg:-left-72"
              />
              <div
                className="absolute top-0 z-10 h-full w-full
                ltr:left-4 rtl:right-12 ltr:xs:left-9 
                ltr:sm:left-4 ltr:md:left-16 rtl:md:right-16
                ltr:lg:left-12 rtl:lg:right-12 ltr:xl:left-0 rtl:xl:right-0"
              >
                <div
                  ref={topChatMessage}
                  data-animation="animate__animated animate__zoomIn"
                  className="absolute"
                >
                  <div
                    className="absolute h-10 w-[227px] rtl:-right-4
                      rtl:w-[220px] md:h-14 md:w-[316px]
                      rtl:md:-right-7 rtl:md:w-[316px]"
                  >
                    <img
                      className="drop-shadow-icon filter"
                      src={topChatbox}
                      alt="right-chatbox"
                    />
                  </div>
                  <p
                    className="absolute w-[200px] translate-x-5 translate-y-1
                    text-xs font-medium leading-4 rtl:translate-x-1
                    rtl:translate-y-3 md:w-[300px] md:translate-x-4
                    md:translate-y-4 md:text-sm rtl:md:translate-y-5"
                  >
                    <span className="font-semibold">
                      <Trans>Hey there!</Trans>
                    </span>
                    <span>&nbsp;👋&nbsp;</span>
                    <span className="font-semibold">
                      <Trans>Here’s your updated cart!</Trans>
                    </span>
                  </p>
                  <div
                    className="absolute h-[34px] w-[34px] translate-x-[232px] 
                    translate-y-1 rtl:translate-x-[55px]
                    md:h-12 md:w-12 md:translate-y-2
                    ltr:md:translate-x-80 rtl:md:translate-x-20"
                  >
                    <img
                      className="rounded-full drop-shadow-icon filter"
                      src={topChathead}
                      alt="right-chathead"
                    />
                  </div>
                </div>
                <div
                  ref={cartCheckoutBox}
                  data-animation="animate__zoomIn animate__delay-1s"
                  className="animate__animated z-10"
                >
                  <div
                    className="absolute translate-x-1 translate-y-12 
                    md:translate-y-16"
                  >
                    <div className="absolute h-60 w-52 md:h-[335px] md:w-72">
                      <img
                        className="drop-shadow-icon filter"
                        src={largeChatbox}
                        alt="middle-chatbox"
                      />
                    </div>
                    <div
                      className="absolute w-[190px] translate-y-2
                      ltr:translate-x-[10px] rtl:translate-x-[-10px]
                      md:w-[270px] md:translate-y-3"
                    >
                      <img
                        src={itemMessage}
                        alt="item-image-message"
                        className="rounded-lg md:rounded-2xl"
                      />
                    </div>
                    <p
                      className="absolute w-[300px] translate-y-[165px]
                      text-xs font-bold text-primary
                      ltr:translate-x-3 rtl:-translate-x-3
                      md:translate-y-[235px] md:text-base"
                    >
                      <Trans>Ice bath with roses</Trans>
                    </p>
                    <div
                      className="text-purple-cyan absolute translate-y-52 
                      text-[10px] font-medium ltr:translate-x-3 
                      rtl:-translate-x-3 md:translate-y-72 md:text-sm"
                    >
                      <span
                        lang="en"
                        className="decoration-purple-cyan-underline"
                      >
                        http://endorphinsunlimited.zbni.co
                      </span>
                    </div>
                    <div
                      className="absolute translate-y-[160px] transform
                      text-primary ltr:translate-x-[165px]
                      rtl:translate-x-[-150px] md:translate-y-[230px]
                      ltr:md:translate-x-[230px] rtl:md:-translate-x-[230px]"
                    >
                      <span
                        className="absolute -translate-x-7 translate-y-1
                        transform text-xs font-medium
                        rtl:-translate-x-8 md:text-base
                        ltr:md:-translate-x-10 rtl:md:-translate-x-8"
                      >
                        <Trans>AED</Trans>
                      </span>
                      <span
                        className="absolute font-semibold ltr:text-lg 
                        rtl:-right-2 rtl:text-2xl ltr:md:text-2xl
                        rtl:md:-right-4 rtl:md:text-3xl"
                      >
                        220
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="absolute z-10 h-[30px] w-[30px]
                  translate-y-44 ltr:translate-x-56 rtl:translate-x-12
                  md:h-10 md:w-10 md:translate-y-64
                  ltr:md:translate-x-[310px] rtl:md:translate-x-[60px]"
                >
                  <div
                    className="absolute h-full w-full
                    -translate-y-20 md:-translate-y-24"
                  >
                    <img
                      ref={instagramIcon}
                      data-animation="animate__fadeInDown animate__delay-2s"
                      className="animate__animated"
                      src={instagramHead}
                      alt="instagram-logo"
                    />
                  </div>
                  <div
                    className="absolute h-full w-full
                    -translate-y-10 md:-translate-y-12"
                  >
                    <img
                      ref={whatsappIcon}
                      data-animation="animate__fadeInDown animate__delay-3s"
                      className="animate__animated"
                      src={whatsappHead}
                      alt="whatsapp-logo"
                    />
                  </div>
                  <div className="absolute h-full w-full translate-y-0">
                    <img
                      ref={messengerIcon}
                      data-animation="animate__fadeInDown animate__delay-4s"
                      className="animate__animated"
                      src={messengerHead}
                      alt="messenger-logo"
                    />
                  </div>
                  <div
                    className="absolute h-full w-full
                    translate-y-10 md:translate-y-12"
                  >
                    <img
                      ref={telegramIcon}
                      data-animation="animate__fadeInDown animate__delay-5s"
                      className="animate__animated"
                      src={telegramHead}
                      alt="telegram-logo"
                    />
                  </div>
                  <div
                    className="absolute h-full w-full
                    translate-y-20 md:translate-y-24"
                  >
                    <img
                      ref={snapchatIcon}
                      data-animation="animate__fadeInDown animate__delay-6s"
                      className="animate__animated"
                      src={snapchatHead}
                      alt="snapchat-logo"
                    />
                  </div>
                </div>
                <div
                  ref={bottomChatMessage}
                  className="animate__animated relative z-[-1] h-full w-full"
                  data-animation="animate__zoomIn animate__delay-3s"
                >
                  <div
                    className="absolute bottom-0
                    -translate-y-10 ltr:translate-x-1 rtl:-translate-x-14
                    md:translate-y-[-60px] rtl:md:translate-x-[-68px]"
                  >
                    <div
                      className="absolute h-10 w-[175px] rtl:mt-1
                      rtl:w-[150px] md:h-14 md:w-[217px] rtl:md:w-[217px]"
                    >
                      <img
                        className="drop-shadow-icon filter"
                        src={botChatbox}
                        alt="left-chatbox"
                      />
                    </div>
                    <p
                      className="absolute w-[217px] translate-x-3 text-xs
                      font-medium ltr:translate-y-4 rtl:-translate-x-4
                      rtl:translate-y-4 md:text-sm ltr:md:translate-x-5
                      rtl:md:-translate-x-6 rtl:md:translate-y-5"
                    >
                      <span className="font-semibold">
                        <Trans>Awesome!</Trans>
                      </span>
                      <span>&nbsp;😎&nbsp;</span>
                      <span className="font-semibold">
                        <Trans>Thank you</Trans>
                      </span>
                    </p>
                    <div
                      className="absolute h-8 w-9 -translate-x-10 translate-y-2
                      rtl:-translate-x-40 md:h-12 md:w-12 md:translate-y-2
                      ltr:md:-translate-x-14 rtl:md:-translate-x-56"
                    >
                      <img
                        className="rounded-full drop-shadow-icon filter"
                        src={botChathead}
                        alt="left-chathead"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
