import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";

export const ShoppersEngagementBeliefChart = ({ innerText = "70" }) => {
  const data = {
    datasets: [
      {
        data: [70, 30],
        borderColor: "#00000000",
        backgroundColor: ["#1EFFFF", "#4E515B"],
      },
    ],
  };
  const options = {
    animation: {
      duration: 3000, // 3 seconds
    },
    cutout: "90%",
    responsive: true,
    tooltips: {
      enabled: false,
    },
    hover: {
      mode: null,
    },
    elements: {},
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="relative">
      <div
        className="z-2 absolute flex h-full w-full flex-row items-center 
        justify-center"
      >
        <label className="text-3xl font-extrabold leading-[45px] text-white">
          {innerText}
          <span className="font-normal">%</span>
        </label>
      </div>
      <div className="z-1 relative">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

ShoppersEngagementBeliefChart.propTypes = {
  innerText: PropTypes.string,
};
