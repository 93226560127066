import Link from "app/components/i18n/Link";
import { useRef } from "react";

import Sprite from "app/components/sprite";
import { useAnimation } from "app/hooks/useAnimation";
import { useI18nLanguage } from "app/hooks/i18n";
import avgBasketValueArabic from "app/images/homepage-images/track-everything/avg-basket-value-arabic.png";
import avgBasketValue from "app/images/homepage-images/track-everything/avg-basket-value.png";
import bigCommerce from "app/images/homepage-images/track-everything/big-commerce.png";
import currentMonthGrowthArabic from "app/images/homepage-images/track-everything/current-month-growth-arabic.png";
import currentMonthGrowth from "app/images/homepage-images/track-everything/current-month-growth.png";
import magento from "app/images/homepage-images/track-everything/magento.png";
import pendingPaymentsArabic from "app/images/homepage-images/track-everything/pending-payments-arabic.png";
import pendingPayments from "app/images/homepage-images/track-everything/pending-payments.png";
import shopify from "app/images/homepage-images/track-everything/shopify.png";
import successfulPaymentsArabic from "app/images/homepage-images/track-everything/successful-payments-arabic.png";
import successfulPayments from "app/images/homepage-images/track-everything/successful-payments.png";
import backgroundImg from "app/images/homepage-images/track-everything/track-everything-bg.png";
import wooCommerce from "app/images/homepage-images/track-everything/woo-commerce.png";
import { Trans } from "react-i18next";

const animationCss = {
  fadeInDown: {
    batch1: "animate__animated animate__fadeInDown",
    batch2: "animate__animated animate__fadeInDown animate__delay-1s",
    batch3: "animate__animated animate__fadeInDown animate__delay-2s",
  },
  fadeInUpBig: {
    batch1: "animate__animated animate__fadeInUp",
    batch2: "animate__animated animate__fadeInUp animate__delay-1s",
    batch3: "animate__animated animate__fadeInUp animate__delay-2s",
    batch4: "animate__animated animate__fadeInUp animate__delay-3s",
  },
};

export const TrackEverything = () => {
  const mainSection = useRef(null);
  const successfulImg = useRef(null);
  const pendingImg = useRef(null);
  const avgBasketImg = useRef(null);
  const monthGrowthImg = useRef(null);
  const mainTopic = useRef(null);
  const mainBody = useRef(null);
  const mainButton = useRef(null);

  useAnimation([
    mainSection,
    successfulImg,
    pendingImg,
    avgBasketImg,
    monthGrowthImg,
    mainTopic,
    mainButton,
    mainBody,
  ]);

  const { locale: language } = useI18nLanguage();

  return (
    <section className="relative h-auto font-montserrat text-white lg:h-screen lg:text-primary">
      <div className="h-full bg-primary pt-20 lg:bg-primary-lightest lg:pt-[84px]">
        <div
          ref={mainSection}
          data-animation="animate__fadeIn animate__animated"
          className="zbooni-layout flex h-full w-full items-center opacity-0"
        >
          <div className="flex h-full w-full flex-col justify-center">
            <div className="relative z-10 h-full w-full py-10 lg:max-h-[700px] xl:max-h-[800px]">
              <Sprite
                name="right-dome"
                fill="#FFF"
                className="absolute top-[50%] z-[-1] hidden h-full max-h-[550px] w-full max-w-[270px] translate-x-0 translate-y-[-50%] lg:block"
              />
              <Sprite
                name="right-dome"
                fill="#FFF"
                className="absolute z-[-1] hidden h-full max-h-[550px] w-full max-w-[270px] translate-x-[265px] rtl:translate-x-[-265px] md:top-[50%] md:translate-y-[-50%] lg:block"
              />
              <Sprite
                name="right-triangle"
                fill="#14141E"
                className="absolute z-[-1] block h-[65vh] max-h-[500px] w-[65vh] max-w-[485px] -translate-x-40 translate-y-28 md:h-full md:max-h-[550px] md:w-full md:max-w-[535px] md:translate-y-8 lg:hidden"
              />
              <div className="relative flex h-full w-full flex-col">
                <div className="flex flex-none">
                  <div className="hidden flex-1 lg:block"></div>
                  <div className="flex flex-1 items-center pb-10">
                    <div className="block lg:hidden">
                      <Sprite
                        name="zbooni-magnify"
                        fill="#FFF"
                        className="inline h-[61px] w-[76px] ltr:mr-5 rtl:ml-5 lg:mx-5 lg:h-[61px] lg:w-[82px]"
                      />
                    </div>
                    <div className="hidden lg:block">
                      <Sprite
                        name="zbooni-magnify"
                        fill="#101820"
                        className="inline h-[61px] w-[76px] ltr:mr-5 rtl:ml-5 lg:mx-5 lg:h-[61px] lg:w-[82px]"
                      />
                    </div>
                    <p className="inline-block w-32 text-2xl font-black leading-tight md:text-3xl lg:text-4xl">
                      <Trans>Track Everything.</Trans>
                    </p>
                  </div>
                </div>
                <div className="flex flex-1 flex-col lg:flex-row">
                  <div className="flex h-full flex-1 flex-col justify-between">
                    <div>
                      <p
                        ref={mainTopic}
                        data-animation={animationCss.fadeInDown.batch1}
                        className="mb-7 max-w-[394px] text-lg font-extrabold md:text-2xl"
                      >
                        <Trans>
                          Gain total control and visibility of your business.
                        </Trans>
                      </p>
                      <div
                        ref={mainBody}
                        data-animation={animationCss.fadeInDown.batch2}
                      >
                        <ul className="list-outside list-disc text-sm font-medium ltr:ml-5 rtl:mr-5 md:font-semibold">
                          <li className="mb-10">
                            <Trans>
                              Easily track all your orders, receipts, customers
                              and sales performance.
                            </Trans>
                          </li>
                          <li className="mt-10">
                            <Trans>
                              Integrate and synchronize with popular commerce
                              solutions.
                            </Trans>
                          </li>
                        </ul>
                        <div className="mt-10 grid w-9/12 grid-cols-4 place-content-start gap-x-5 gap-y-8 md:w-7/12 lg:w-full lg:grid-cols-10 lg:place-items-center">
                          <div className="col-span-2">
                            <img
                              alt=""
                              src={shopify}
                              className="w-24 md:w-28 lg:w-auto"
                            />
                          </div>
                          <div className="col-span-2">
                            <img
                              alt=""
                              src={magento}
                              className="w-24 md:w-28 lg:w-auto"
                            />
                          </div>
                          <div className="col-span-2">
                            <img
                              alt=""
                              src={bigCommerce}
                              className="w-24 md:w-28 lg:w-auto"
                            />
                          </div>
                          <div className="col-span-2">
                            <img
                              alt=""
                              src={wooCommerce}
                              className="w-24 md:w-28 lg:w-auto"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        ref={mainButton}
                        data-animation={animationCss.fadeInDown.batch3}
                      >
                        <Link
                          to="/features/track-everything/#details"
                          id="cta_homepage_trackEverything_showMeMore_in"
                        >
                          <button className="dark-green-gradient-btn mt-12 hidden rounded-full px-8 py-4 lg:block">
                            <p className="!font-extrabold">
                              <Trans>Show me more</Trans>
                            </p>
                          </button>
                          <button className="yellow-green-cyan-btn mb-5 mt-10 block rounded-full px-5 py-3 text-sm lg:hidden">
                            <p className="!font-extrabold text-primary">
                              <Trans>Show me more</Trans>
                            </p>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="relative -mb-10 -ml-7 h-full min-h-[475px] w-screen flex-1 bg-primary-lightest rtl:-mr-7 md:min-h-[650px] lg:m-0 lg:h-full lg:min-h-0 lg:w-full lg:bg-transparent">
                    <div className="absolute h-full w-[25vh] translate-x-12 translate-y-10 rtl:-translate-x-36 md:translate-x-28 md:translate-y-28 rtl:md:-translate-x-[28rem] lg:translate-x-0 lg:translate-y-10 rtl:lg:-translate-x-[13rem] xl:w-[30vh] xl:translate-y-0 rtl:xl:-translate-x-[25rem]">
                      <div
                        ref={monthGrowthImg}
                        data-animation={animationCss.fadeInUpBig.batch4}
                      >
                        <img
                          className="absolute w-[130px] translate-x-10 translate-y-10 drop-shadow-icon filter rtl:-translate-x-8 md:w-[193px] md:translate-x-20 rtl:md:-translate-x-4"
                          src={
                            language == "ar"
                              ? currentMonthGrowthArabic
                              : currentMonthGrowth
                          }
                          alt="current-month-growth"
                        />
                      </div>
                      <div
                        ref={avgBasketImg}
                        data-animation={animationCss.fadeInUpBig.batch3}
                      >
                        <img
                          className="absolute w-[150px] -translate-x-4 translate-y-[135px] drop-shadow-icon filter rtl:translate-x-8 md:w-[201px] md:translate-x-5 md:translate-y-[150px] rtl:md:translate-x-20"
                          src={
                            language == "ar"
                              ? avgBasketValueArabic
                              : avgBasketValue
                          }
                          alt="average-basket-value"
                        />
                      </div>
                      <div
                        ref={successfulImg}
                        data-animation={animationCss.fadeInUpBig.batch1}
                      >
                        <img
                          className="absolute w-[180px] -translate-x-8 translate-y-[235px] rounded-lg shadow-[0_0_17px_2px_rgba(0,0,0,0.07)] rtl:translate-x-20 md:w-[266px] md:translate-y-[295px]"
                          src={
                            language == "ar"
                              ? successfulPaymentsArabic
                              : successfulPayments
                          }
                          alt="successful-payments"
                        />
                      </div>
                      <div
                        ref={pendingImg}
                        data-animation={animationCss.fadeInUpBig.batch2}
                      >
                        <img
                          className="absolute w-[150px] translate-x-16 translate-y-52  rounded-lg shadow-[0_0_17px_2px_rgba(0,0,0,0.07)] rtl:-translate-x-16 md:w-[201px] md:translate-x-28 md:translate-y-60 xl:translate-x-40"
                          src={
                            language == "ar"
                              ? pendingPaymentsArabic
                              : pendingPayments
                          }
                          alt="pending-payments"
                        />
                      </div>
                    </div>
                    <img
                      className="h-96 w-[100%] object-cover md:h-[85%] lg:rounded-2xl xl:h-[80%]"
                      src={backgroundImg}
                      alt="track-everything-bg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
