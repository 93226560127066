import Link from "app/components/i18n/Link";
import { useRef } from "react";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import Sprite from "app/components/sprite";
import { isRtlLang } from "app/hooks/rtl-detect";
import { useAnimation } from "app/hooks/useAnimation";
import { useI18nLanguage } from "app/hooks/i18n";
import paymentMethodsArabic from "app/images/homepage-images/accept-payments/payment-methods-arabic.png";
import paymentMethods from "app/images/homepage-images/accept-payments/payment-methods.png";
import ClientOnly from "app/components/ClientOnly";
import { Trans } from "react-i18next";

export const AcceptPayments = () => {
  const mainSection = useRef(null);
  const paymentsCard = useRef(null);
  const paymentGatewaySection = useRef(null);
  const paymentOptionSection = useRef(null);

  const { locale: language } = useI18nLanguage();

  useAnimation([
    mainSection,
    paymentsCard,
    paymentGatewaySection,
    paymentOptionSection,
  ]);

  return (
    <section className="relative min-h-[700px] overflow-x-hidden font-montserrat text-primary lg:h-screen">
      <div className="h-full bg-primary-lightest pt-20">
        <div className="zbooni-layout flex h-full w-full items-center">
          <div
            ref={mainSection}
            data-animation="animate__fadeIn animate__animated"
            className="flex h-full w-full flex-col justify-center opacity-0"
          >
            <div className="relative z-10  h-full w-full py-10 lg:max-h-[700px] xl:max-h-[800px]">
              <Sprite
                name="donut"
                fill="#FFF"
                className="absolute z-[-1] h-[80vh] w-[80vh] translate-y-56 md:top-[50%] md:translate-x-24 md:translate-y-[-50%] rtl:md:-translate-x-24 lg:max-h-[620px] lg:max-w-[620px] xl:max-h-[720px] xl:max-w-[720px] xl:translate-x-28 rtl:xl:-translate-x-28"
              />
              <div className="relative flex h-full w-full flex-col lg:flex-row">
                <div className="mb-72 flex w-full flex-col lg:m-0 lg:w-5/12 lg:justify-between">
                  <div className="flex items-center">
                    <Sprite
                      name="zbooni-wink"
                      className="inline h-16 w-20 ltr:mr-5 rtl:ml-4"
                    />
                    <p className="inline-block w-1/5 text-3xl font-black leading-tight xl:text-4xl">
                      <Trans>Accept payments.</Trans>
                    </p>
                  </div>
                  <div>
                    <p className="my-5 w-10/12 font-extrabold md:text-xl lg:my-0 lg:w-9/12 xl:text-2xl">
                      <Trans>Enable payments seamlessly starting today.</Trans>
                    </p>
                    <Link
                      to="/features/accept-payments/#details"
                      id="cta_homepage_acceptPayments_tellMeMore_in"
                    >
                      <button className="dark-green-gradient-btn mt-4 rounded-full py-2 md:py-4 lg:mt-10">
                        <p className="text-sm !font-extrabold">
                          <Trans>Tell me more</Trans>
                        </p>
                      </button>
                    </Link>
                  </div>
                  <div className="my-10 lg:my-0">
                    <ClientOnly>
                      <InView as="div" rootMargin="50px">
                        {({ inView, ref }) => (
                          <div ref={ref} style={{ height: 36 }}>
                            {inView ? (
                              <CountUp
                                decimals={1}
                                end={2.7}
                                suffix={isRtlLang(language) ? "" : "x"}
                                prefix={isRtlLang(language) ? "x" : ""}
                                duration={1}
                                className="inline text-3xl font-extrabold"
                              />
                            ) : (
                              <p className="text-3xl font-extrabold">
                                <Trans>2.7x</Trans>
                              </p>
                            )}
                          </div>
                        )}
                      </InView>
                    </ClientOnly>
                    <p className="text-sm font-medium">
                      <Trans>vs. eCommerce</Trans>
                    </p>
                    <p className="text-sm font-medium">
                      <Trans>average basket</Trans>
                    </p>
                  </div>
                </div>
                <div className="relative -mb-10 flex w-screen flex-1 text-white ltr:-ml-7 rtl:-mr-7 lg:m-0 lg:w-full">
                  <div className="relative inline flex-1 bg-primary-light lg:ml-12 lg:mr-0 lg:rounded-2xl xl:mx-12">
                    <div className="relative flex h-full flex-col lg:flex-row">
                      <div className="relative flex h-full w-full flex-col lg:w-3/12 lg:justify-center">
                        <div
                          ref={paymentsCard}
                          data-animation="animate__animated fade-slide-in-left"
                          className="relative h-full max-h-px"
                        >
                          <div className="absolute left-[50%] -translate-x-2/4 translate-y-[-63.5%] lg:left-0 lg:-translate-x-2/4 lg:-translate-y-2/4 rtl:lg:translate-x-2">
                            <img
                              className="min-w-[247px] max-w-[247px] rounded-[28px] shadow-[0_0_17px_4px_rgba(0,0,0,0.08)] md:w-[20vh] md:max-w-[200px] lg:w-[30vh] lg:max-w-[240px] xl:w-[35vh] xl:max-w-[315px]"
                              src={
                                language == "ar"
                                  ? paymentMethodsArabic
                                  : paymentMethods
                              }
                              alt="payment-methods"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="mx-auto mt-52 flex min-h-[750px] max-w-[375px] flex-col justify-around px-7 pb-16 lg:m-0 lg:h-full lg:min-h-0 lg:max-w-none lg:pb-0 lg:pl-8 lg:pr-10 xl:pr-16">
                          <div
                            ref={paymentOptionSection}
                            data-animation="animate__fadeInDown animate__delay-1s"
                            className="animate__animated pt-6"
                          >
                            <p className="w-full text-xl font-extrabold lg:text-lg xl:text-[22px]">
                              <Trans>Payments powered by Zbooni.</Trans>
                            </p>
                            <p className="mb-8 mt-4 text-xs">
                              <Trans>
                                Available in Jordan, UAE, KSA, and Egypt.
                              </Trans>
                            </p>
                            <div className="mt-10 grid grid-flow-row grid-cols-12 content-around gap-y-8 md:m-0">
                              <div className="col-span-4 flex justify-start lg:col-span-3">
                                <Sprite
                                  name="accept-payments-samsung-pay"
                                  className="h-9 w-20 lg:h-7 lg:w-[62px] xl:h-[31px] xl:w-[68px]"
                                />
                              </div>
                              <div className="col-span-4 flex justify-center lg:col-span-3 xl:justify-start">
                                <Sprite
                                  name="accept-payments-mada"
                                  className="h-9 w-20 lg:h-6 lg:w-10 xl:h-[23px] xl:w-[68px]"
                                />
                              </div>
                              <div className="col-span-4 flex justify-center lg:col-span-3 xl:justify-start">
                                <Sprite
                                  name="accept-payments-stc-pay"
                                  className=" h-8 w-20 lg:h-4 lg:w-10 xl:h-5 xl:w-[68px]"
                                />
                              </div>
                              <div className="col-span-4 flex justify-start lg:col-span-3">
                                <Sprite
                                  name="accept-payments-fawry"
                                  className="h-[32px] w-[65px] lg:h-6 lg:w-16 xl:h-7 xl:w-[58px]"
                                />
                              </div>
                              <div className="col-span-4 flex justify-center md:justify-start lg:col-span-3">
                                <Sprite
                                  name="accept-payments-orange-money"
                                  className="h-7 w-20 lg:h-5 lg:w-16 xl:h-5 xl:w-[68px]"
                                />
                              </div>
                              <div className="col-span-4 flex justify-center lg:col-span-3 xl:justify-start">
                                <Sprite
                                  name="accept-payments-meeza"
                                  className="h-9 w-20 lg:h-5 lg:w-10 xl:h-7 xl:w-[43px]"
                                />
                              </div>
                              <div className="col-span-4 flex justify-start lg:col-span-3 lg:justify-center xl:justify-start">
                                <Sprite
                                  name="accept-payments-vodafone"
                                  className="h-5 w-[78px] lg:h-5 lg:w-10 xl:h-[17px] xl:w-[69px]"
                                />
                              </div>
                              <div className="col-span-4 flex justify-center lg:col-span-3 xl:justify-start">
                                <Sprite
                                  name="accept-payments-val"
                                  className="h-9 w-20 lg:h-5 lg:w-10 xl:h-[30px] xl:w-[69px]"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="my-10 flex items-center lg:my-0">
                            <div className="h-px flex-1 bg-gradient-to-r from-yellowgreen to-cyan-darkest" />
                            <div className="flex-none px-3 text-lg font-bold">
                              <Trans>or</Trans>
                            </div>
                            <div className="h-px flex-1 bg-gradient-to-l from-yellow-dark via-green to-cyan" />
                          </div>
                          <div
                            ref={paymentGatewaySection}
                            data-animation="animate__fadeInDown animate__delay-1s"
                            className="animate__animated pb-6"
                          >
                            <p className="max-w-[335px] text-xl font-extrabold lg:text-lg xl:text-[22px]">
                              <Trans>
                                Integrate with your existing payment provider.
                              </Trans>
                            </p>
                            <p className="mb-8 mt-4 text-xs">
                              <Trans>Available worldwide.</Trans>
                            </p>
                            <div
                              className="mt-10 grid grid-flow-row
                              grid-cols-12 content-around gap-y-8 md:m-0"
                            >
                              <div className="col-span-6 flex justify-start lg:col-span-3">
                                <Sprite
                                  name="stripe"
                                  className="h-9 w-20 lg:h-5 lg:w-10 xl:h-8 xl:w-16"
                                />
                              </div>
                              <div className="col-span-6 flex justify-center md:justify-start lg:col-span-3">
                                <Sprite
                                  name="checkout"
                                  className="h-8 w-32 lg:mr-2 lg:h-6 lg:w-16 xl:h-7 xl:w-24"
                                />
                              </div>
                              <div className="col-span-6 flex justify-start lg:col-span-3 lg:justify-center">
                                <Sprite
                                  name="adyen"
                                  className="h-8 w-20 lg:h-4 lg:w-10 xl:h-5 xl:w-14"
                                />
                              </div>
                              <div className="col-span-6 flex justify-center lg:col-span-3 lg:justify-center">
                                <Sprite
                                  name="amazon"
                                  className="h-10 w-24 lg:h-8 lg:w-16 xl:h-9 xl:w-20"
                                />
                              </div>
                              <div className="col-span-6 flex justify-start lg:col-span-3">
                                <Sprite
                                  name="paytabs"
                                  className="h-7 w-20 lg:h-5 lg:w-16 xl:h-7 xl:w-20"
                                />
                              </div>
                              <div className="col-span-6 flex justify-center lg:col-span-3 lg:justify-end">
                                <Sprite
                                  name="noon-payments"
                                  className="mr-2 h-9 w-20 lg:h-6 lg:w-10 xl:h-7 xl:w-14"
                                />
                              </div>
                              <div className="col-span-6 flex justify-start lg:col-span-6 lg:justify-center">
                                <Sprite
                                  name="jumia-pay"
                                  className="h-6 w-32 lg:h-3 lg:w-20 xl:h-4 xl:w-24"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
