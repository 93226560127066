/* eslint-disable jsx-a11y/iframe-has-title */
import TapIntoCCommerceBanner from "app/components/banners/tap-into-ccommerce";
import Sprite from "app/components/sprite";
import Link from "app/components/i18n/Link";
import classNames from "classnames";
import { useState } from "react";
import { Trans } from "react-i18next";

const stores = [
  {
    name: "The MotorHub",
    logo: "https://s3-eu-west-1.amazonaws.com/merchants.zbooni/media/business_logos/C8B81F42-B21D-4001-9468-7689D9245FEF.jpg",
    video: "https://www.youtube.com/embed/6Jpej7-mEbY",
  },
  {
    name: "Basketball Hub",
    logo: "https://s3-eu-west-1.amazonaws.com/merchants.zbooni/media/business_logos/6887EC40-C3B6-4F94-AEF0-B8183006A79D.jpg",
    video: "https://www.youtube.com/embed/DT7KlnQWy4I",
  },
  {
    name: "Dish Catering",
    logo: "https://s3-eu-west-1.amazonaws.com/merchants.zbooni/media/business_logos/AAEAA1B2-633D-4BC0-8461-C6975C75BE79.jpg",
    video: "https://www.youtube.com/embed/k-1lNvRvhro",
  },
  {
    name: "Blossom Tree",
    logo: "https://media.zbooni.shop/assets/web/marketplace/logo/blossom-tree-flower-shop.jpg",
    video: "https://www.youtube.com/embed/rs7jj6VWE9E",
  },
  {
    name: "Wear That",
    logo: "https://s3-eu-west-1.amazonaws.com/merchants.zbooni/media/business_logos/avatar_gcSZ9Zd",
    video: "https://www.youtube.com/embed/JgFI6tqS__0",
  },
];

export const WhoWeServeSection = () => {
  const [currentStore, setCurrentStore] = useState(stores[0]);

  return (
    <section className="overflow-hidden bg-secondary-lightest font-montserrat">
      <div className="zbooni-layout relative flex h-full items-center py-20 text-primary lg:min-h-[900px]">
        <div className="absolute -top-12 left-0 z-0 lg:left-24">
          <Sprite
            name="triangle-wws"
            className="h-[370px] w-[300px] sm:ml-0 sm:h-[488px] sm:w-[415px] md:-ml-6 md:h-[488px] md:w-[415px] lg:-ml-20 lg:h-[488px] lg:w-[415px] xl:-ml-20 xl:h-[488px] xl:w-[415px]"
          />
        </div>
        <div className="relative flex h-full w-full flex-col items-center justify-center">
          <h1 className="mb-5 text-[33px] font-extrabold">
            <Trans>Who We Serve.</Trans>
          </h1>
          <p className="mb-11 px-6 text-center text-base font-semibold md:mb-14 lg:px-0">
            <Trans>See how cCommerce is enabling businesses like yours.</Trans>
          </p>
          <div className="relative flex h-[220px] w-full items-center justify-center sm:h-[360px]">
            <div className="absolute z-0 hidden w-full rounded-lg bg-secondary-light lg:block lg:h-[310px] lg:max-w-[700px]" />
            <div className="z-1 absolute hidden w-full rounded-lg bg-white lg:block lg:h-[340px] lg:max-w-[670px]" />
            <div className="z-2 absolute h-full w-full overflow-hidden rounded-lg md:w-[640px] lg:max-w-[640px]">
              <div className="relative h-full px-0 pb-0">
                <iframe
                  src={
                    currentStore.video + "?rel=0&amp;fs=0&amp;modestbranding=1"
                  }
                  className="absolute bottom-0 left-0 top-0 h-full w-full"
                />
              </div>
            </div>
          </div>
          <div className="w-full overflow-x-auto pb-10 pt-8 sm:w-[680px] lg:overflow-hidden">
            <div className="flex w-[680px] flex-nowrap overflow-x-auto text-xs">
              {stores.map((store, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      setCurrentStore(store);
                    }}
                    className={classNames(
                      "m-auto",
                      currentStore.name == store.name
                        ? "opacity-100"
                        : "opacity-50"
                    )}
                  >
                    <div
                      className={classNames(
                        "mx-auto flex h-[74px] w-[74px] flex-row items-center justify-center rounded-full",
                        currentStore.name == store.name
                          ? "bg-gradient-to-r from-cyan-dark to-yellowgreen"
                          : "bg-transparent"
                      )}
                    >
                      <img
                        src={store.logo}
                        alt={store.name}
                        className="mx-auto h-[67px] w-[67px] rounded-full"
                      />
                    </div>
                    <p className="mt-2 text-center font-semibold">
                      {store.name}
                    </p>
                  </button>
                );
              })}
            </div>
          </div>
          <Link to="/who-we-serve/" id="cta_homepage_whoWeServe_learnMore_in">
            <button className="dark-green-gradient-btn !px-8 !py-4">
              <p>
                <Trans>Learn more</Trans>
              </p>
            </button>
          </Link>
        </div>
      </div>
      <div className="zbooni-layout pb-10 sm:pb-12 lg:pb-16">
        <TapIntoCCommerceBanner />
      </div>
    </section>
  );
};
