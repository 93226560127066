import cardImages from "app/images/ccommerce-report/card-images.png";
import CardBgHalfCircleSm from "app/images/svgs/ccommerce-report/half-circle-sm.svg";
import CardBgTriangleSm from "app/images/svgs/ccommerce-report/triangle-sm.svg";
import CardBgZigzagSm from "app/images/svgs/ccommerce-report/zigzag-sm.svg";
import Link from "app/components/i18n/Link";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

const DownloadReportButton = ({ className }) => (
  <Link
    to="/the-mena-ccommerce-report-2024"
    className={classNames(
      className,
      "w-full h-auto sm:max-w-[200px] sm:h-10 text-xs xs:text-sm sm:text-xs leading-normal font-extrabold sm:font-bold text-white text-center mt-4 px-5 py-3 xs:px-7 xs:py-4 sm:px-5 sm:py-3 bg-primary rounded-full shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] sm:shadow-none"
    )}
  >
    <Trans>Download the Report</Trans>
  </Link>
);
DownloadReportButton.propTypes = {
  className: PropTypes.string,
};

export default function CcommerceReport() {
  return (
    <div className="z-10 md:w-[490px] md:rounded-2xl">
      <div className="bg-cyan relative mx-auto p-5 sm:p-0 gap-y-3 sm:gap-0 flex flex-col sm:flex-row item-center justify-center rounded-2xl h-[420px] sm:h-[200px] sm:max-w-[490px] md:max-w-none lg:border-none overflow-hidden">
        <div className="flex flex-col self-center max-w-[284px] sm:w-1/2 gap-y-1">
          <div className="flex flex-col">
            <p className="leading-normal font-medium text-primary text-center sm:text-left rtl:sm:text-right">
              <Trans>
                <span className="text-xs xs:text-sm sm:text-xs block">
                  The MENA
                </span>
                <span className="text-base xs:text-lg sm:text-base">
                  cCommerce Report 2024
                </span>
              </Trans>
            </p>
          </div>
          <p className="text-base xs:text-lg sm:text-base leading-normal font-extrabold text-primary text-center sm:text-left rtl:sm:text-right">
            <Trans>How WhatsApp is changing the way we shop</Trans>
          </p>
          <DownloadReportButton className="hidden sm:block" />
        </div>
        <div className="flex items-center justify-center w-auto h-full">
          <div className="relative w-[190px] h-[185px]">
            <div className="hidden sm:block">
              <div className="absolute z-0 top-10 rtl:top-5 -right-[27px]">
                <img alt="" src={CardBgZigzagSm} />
              </div>
              <div className="absolute z-0 bottom-8 -left-[18px]">
                <img alt="" src={CardBgTriangleSm} />
              </div>
              <div className="absolute z-0 -bottom-10 -right-[38px]">
                <img alt="" src={CardBgHalfCircleSm} />
              </div>
            </div>
            <div className="block sm:hidden">
              <div className="absolute z-0 -top-2.5 -left-[42px]">
                <img alt="" src={CardBgZigzagSm} />
              </div>
              <div className="absolute z-0 -bottom-5 -right-[65px]">
                <img alt="" src={CardBgHalfCircleSm} />
              </div>
              <div className="absolute z-0 -top-4 -right-[46px]">
                <img alt="" src={CardBgTriangleSm} />
              </div>
            </div>
            <img
              src={cardImages}
              className="absolute z-10 w-full h-full"
              alt=""
            />
          </div>
        </div>
        <DownloadReportButton className="block sm:hidden" />
      </div>
    </div>
  );
}
