/**
 * @typedef {Object} GradientColor
 * @property {string} color - The hex code of the color in string format.
 * @property {Number} stop - The color stop
 */

/**
 * @typedef {createCanvasLinearGradientParams}
 * @property {CanvasRenderingContext2D} context
 * @property {import('chart.js').ChartArea} chartArea
 * @property {Array<GradientColor>} colors
 * @property {any} position
 */

/**
 * Creates and returns a linear gradient using a given context.
 * Reference: https://www.chartjs.org/docs/3.1.0/samples/advanced/linear-gradient.html
 * @param {createCanvasLinearGradientParams} param1
 * @return {CanvasGradient}
 */
export function createCanvasLinearGradient({
  context,
  chartArea,
  colors,
  position,
}) {
  let width;
  let height;
  let gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    let gradientParams = [0, chartArea.bottom, 0, chartArea.top];
    if (typeof position == "function") {
      gradientParams = position(chartArea);
    } else if (Array.isArray(position)) {
      gradientParams = position;
    }
    gradient = context.createLinearGradient(...gradientParams);
    for (const { stop, color } of colors) {
      gradient.addColorStop(stop, color);
    }
  }
  return gradient;
}
